<template>

    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <v-btn icon color="rgb(155 134 203)" to="/panel/admin/harmonys" class="mr-2">
                    <v-icon>mdi-keyboard-return</v-icon>
                </v-btn>
                <span class="title">Harmony:
                    <span class="text-capitalize">{{ siteName }}</span>
                </span>
                <p class="desc mt-0 mb-1">En esta sección podrás visualizar todas sus estadísticas</p>
            </v-alert>
        </v-col>

        <v-row class="d-block d-md-flex">
            <v-col class="col-12 col-md-4" v-if="siteId != 'all'">
                <div
                    class="profile d-flex text-left justify-space-around justify-md-center align-center px-5 py-6 rounded-xl fill-height">
                    <div class="d-flex flex-column justify-between">
                        <h4 class="wrap text-capitalize">{{ selectedSite }}</h4>
                        <p class="wrap">{{ this.statistics.description }}</p>
                    </div>
                    <img :src="statistics.logo_path ? statistics.logo_path : require('../../assets/illustrations/no-photo.jpg')"
                        alt="" width="100" height="100" class="rounded-pill" />
                </div>
            </v-col>
            <v-col class="col-12" :class="getAll()">
                <div class="profile d-flex text-left justify-center align-center px-3 py-6 rounded-xl fill-height">
                    <v-row class="fill-height  justify-space-around justify-md-space-around">

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs" v-on="on">
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(statistics.visits) }}</h4>
                                        <p>{{ $t('Statistics.totalViews') }}</p>
                                        <v-progress-circular v-if="loading" indeterminate color="rgb(151, 138, 206)"
                                            class="mt-4" />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-eye-check</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-totalViews') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs" v-on="on">
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(statistics.uniques) }}</h4>
                                        <p>{{ $t('Statistics.uniqueViews') }}</p>
                                        <v-progress-circular v-if="loading" indeterminate color="rgb(151, 138, 206)"
                                            class="mt-4" />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-account-eye</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-UniqueViews') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs" v-on="on">
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(statistics.clicks) }}</h4>
                                        <p>{{ $t('Statistics.totalClicks') }}</p>
                                        <v-progress-circular v-if="loading" indeterminate color="rgb(151, 138, 206)"
                                            class="mt-4" />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0"
                                        size="40">mdi-cursor-default-click</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-TotalClicks') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs" v-on="on">
                                    <v-container class="m-w mt-0  pa-md-0 m-auto">
                                        <h4 class="number">{{ formatNumber(statistics.unique_clicks) }}</h4>
                                        <p>{{ $t('Statistics.totalClicksUniques') }}</p>
                                        <v-progress-circular v-if="loading" indeterminate color="rgb(151, 138, 206)"
                                            class="mt-4" />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0"
                                        size="40">mdi-cursor-default-click</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-TotalUniqueClicks') }}</span>
                        </v-tooltip>

                        <!-- estadisticas admin por usuario -->
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs" v-on="on">
                                    <v-container class="m-w mt-0  pa-md-0 m-auto">
                                        <h4 class="number">${{ totalAmount }}</h4>
                                        <p>{{ $t('Statistics.totalEarnings') }}</p>
                                        <v-progress-circular v-if="loading" indeterminate color="rgb(151, 138, 206)"
                                            class="mt-4" />
                                    </v-container>
                                    <v-icon v-if="!loading" size="40"
                                        class="icon pa-3  pa-md-0">mdi-cash-multiple</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-TotalEarnings') }}</span>
                        </v-tooltip>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-col class="chart rounded-xl mt-6">
            <v-row class="d-flex justify-end">
                <v-col cols="12" md="3">
                    <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="selectedStartMonth" :label="$t('Statistics.start-date')"
                                color="#8e84c0" filled prepend-inner-icon="mdi-calendar-start mt-3" readonly
                                v-bind="attrs" v-on="on" rounded dense></v-text-field>
                        </template>
                        <v-date-picker elevation="24" v-model="selectedStartMonth" @input="onStartMonthSelected"
                            :first-day-of-week="1" :locale="this.lang" color="#8e84c0" type="month"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                    <v-menu v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="selectedEndMonth" :label="$t('Statistics.end-date')" color="#8e84c0"
                                filled prepend-inner-icon="mdi-calendar-end mt-3" readonly v-bind="attrs" v-on="on"
                                rounded dense></v-text-field>
                        </template>
                        <v-date-picker elevation="24" v-model="selectedEndMonth" @input="onEndMonthSelected"
                            :first-day-of-week="1" :locale="this.lang" type="month" color="#8e84c0"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select class="mr-12" :items="itemsRange" color="#8e84c0" filled :label="$t('Statistics.ranks')" dense
                        rounded v-model="selectedRange" @change="showSelectedRange"></v-select>
                </v-col>
            </v-row>
            <bar-chart class=" mx-auto mt-2 px-sm-12 px-5 pb-12 rounded-xl" :chart-data="chartData"></bar-chart>
        </v-col>

        <template v-if="siteId == 'all'">
            <!-- Data table for all sites -->
            <div cols="12" class="rounded-lg px-5 py-4 mt-7 rounded-xl chart">
                <h4 class="text-left">Enlace con más clics</h4>
                <v-data-table :headers="tableHeaders" :items="statistics ? statistics.url_or_platforms : []"
                    :items-per-page="10" :loading="loading" class="mt-4">
                    <template v-slot:item.name="{ item }">
                        <v-icon class="pa-3 pa-md-0" color="gray" size="15">mdi-link</v-icon> &nbsp;
                        <a :href="item.real_url" target="_blank">{{ item.name }}</a>
                    </template>

                    <template v-slot:item.count="{ item }">
                        {{ formatNumber(item.count) }}&nbsp;
                        <v-icon class="pa-3 pa-md-0" color="gray" size="15">mdi-cursor-default-click</v-icon>
                    </template>
                </v-data-table>
            </div>
        </template>

        <v-row no-gutters v-else>
            <v-col class="chart rounded-xl mt-6">
                <horizontal-bar-chart class=" mx-auto mt-2 px-sm-12 px-5 pb-12 rounded-xl"
                    :horizontal-data="horizontalBarChartData"></horizontal-bar-chart>
            </v-col>
        </v-row>

        <!-- tabla conversiones -->
        <div cols="12" class="rounded-lg px-5 py-4 mt-7 rounded-xl chart">

            <div>
                <h4 class="text-center">Conversiones</h4>
                <p class="text-disabled mb-0" style="font-size: 14px;">
                    Total: ${{ getTotEarning(totalConversionsAmount) }} USD
                </p>

            </div>
            <v-row class="d-flex justify-left mt-0">
                <v-col class="pa-0 px-5 col-12 col-sm-6 col-md-3">
                    <v-select hide-details v-model="selectedConversions" :items="itemsForView" label="Ver"
                        @change="getConversions" color="#9BA9BD" class="mb-4 mt-6"></v-select>
                </v-col>
            </v-row>
            <v-data-table :headers="headersConversions" :items="conversions" :items-per-page="10"
                :loading="loadingConversions" class="mt-4">
                <template v-slot:item.platform="{ item }">
                    {{ item.platform }}
                </template>
                <template v-slot:item.platform_percent="{ item }">
                    {{ formatPercentage(item.platform_percent) }}
                </template>
                <template v-slot:item.payment_creator="{ item }">
                    {{ formatCurrency(item.payment_creator) }}
                </template>
                <template v-slot:item.payment_date="{ item }">
                    {{ formatDate(item.payment_date) }}
                </template>
            </v-data-table>
        </div>

        <div cols="12" class="rounded-lg px-5 py-4 mt-7 rounded-xl chart">
            <h4 class="text-center">Ganancias referidos</h4>
            <p class="text-disabled text-center" style="font-size: 14px;">
                Total: ${{tot_earnings}} USD
            </p>
            <v-data-table :headers="headersEarningsReferrals" :items="earnings_referrals" :items-per-page="10"
                :loading="loadingEarningsReferrals" class="mt-4">
                <template v-slot:item.total_amount="{ item }">
                    {{ formatDecimal(item.total_amount) }}
                </template>
                <template v-slot:item.ranges="{ item }">
                    {{ item.date_earning_referral }} &nbsp;al&nbsp; {{ item.date_earning_referral_end }}
                </template>
            </v-data-table>
        </div>

        <v-row no-gutters>
            <v-col class="chart rounded-xl mt-6">
                <bar-chart-percent class="mx-auto mt-2 px-sm-12 px-5 pb-12 rounded-xl"
                    :chart-data="chartPercent"></bar-chart-percent>
            </v-col>
        </v-row>

        <v-row class="pt-2">
            <v-col class="col-12 col-sm-6 col-md-3" v-if="Object.keys(deviceStats).length">
                <div class="pa-5 mt-4 pb-0 chart rounded-xl text-left">
                    <h4>{{ $t('Statistics.mostUsedDevices') }}</h4>
                    <doughnut-chart style="max-height:250px ; margin:auto;"
                        :doughnut-data="deviceStatsData"></doughnut-chart>
                    <div>
                        <v-icon class="arrow" @click="deviceExpand = !deviceExpand"
                            :class="{ 'actived-arrow': deviceExpand }">mdi-play</v-icon>
                        <span class="show">{{ deviceExpand ? $t('Statistics.hide') : $t('Statistics.show_more')
                            }}</span>
                        <v-expand-transition>
                            <v-card v-show="deviceExpand" class="mx-auto chart" height="100" width="100">
                                <ul class="list">
                                    <li v-for="(count, device, index) in deviceStats" :key="device">
                                        <span :style="{ backgroundColor: deviceColors[index]}" class="color-box"></span>
                                        {{ device }}: {{ count }}
                                    </li>
                                </ul>
                            </v-card>
                        </v-expand-transition>
                    </div>
                </div>
            </v-col>
            <v-col class="col-12 col-sm-6 col-md-6">
                <div class="pa-5 pr-2  mt-4 pb-0 chart rounded-xl text-left " style="height: 323px;">
                    <h4>Países y regiones principales</h4>
                    <v-col cols="12" class="pa-0">
                        <v-tabs v-model="activeTab" color="#8e84c0" class="round-tab mb-3 d-flex justify-left ">
                            <v-tab v-for="(tab, index) in tabs" :key="index" class="custom-tab ">
                                {{ tab }}
                            </v-tab>
                        </v-tabs>
                    </v-col>

                    <v-col class="col-12 px-0 pb-2 mt-0 pt-0">
                        <v-responsive class="scroll-data pb-2">
                            <v-data-table v-show="activeTab === 0" :headers="headersCountry" :items="countryData"
                                :loading="loading" hide-default-footer disable-pagination>

                                <template v-slot:item.name="{ item }">
                                    <div class="item-container">
                                        <span>{{ item.name }}</span>
                                    </div>
                                </template>

                                <template v-slot:item.total="{ item }">
                                    <span>{{ formatNumber(item.total) }}</span>
                                </template>

                                <template v-slot:item.flag="{ item }">
                                    <v-img class="flags" :src="item.flag" />
                                </template>

                                <template v-slot:item.percentage="{ item }">
                                    <v-chip color="#f4e8ff">
                                        {{ item.percentage }}%
                                    </v-chip>
                                </template>
                            </v-data-table>

                            <v-data-table v-show="activeTab === 1" :headers="headersRegion" :items="regionData"
                                hide-default-footer disable-pagination>
                                <template v-slot:item.total="{ item }">
                                    <span>{{ formatNumber(item.total) }}</span>
                                </template>
                            </v-data-table>
                        </v-responsive>
                    </v-col>
                </div>
            </v-col>

            <v-col class="col-12 col-sm-6 col-md-3">
                <div class="pa-5 mt-4 pb-0 chart rounded-xl text-left ">
                    <h4>{{ $t('Statistics.topCities') }}</h4>
                    <v-col class="col-12 px-0 pb-2 mt-0 pt-0">
                        <v-responsive class="scroll-data ">
                            <v-data-table :headers="headersCity" :items="cityData" :loading="loading"
                                hide-default-footer disable-pagination>
                                <template v-slot:item.total="{ item }">
                                    <span>{{ formatNumber(item.total) }}</span>
                                </template>
                            </v-data-table>
                        </v-responsive>
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import axios from "@/axios.js";
import { Bar, Doughnut, HorizontalBar } from "vue-chartjs";
import "chartjs-plugin-colorschemes";
import moment from "moment";
import { getCountryFlagUrl } from "@/utils/countries.js";

export default {
    name: "Statistics",
    components: {
        "bar-chart": {
            extends: Bar,
            props: ["chartData"],
            mounted() {
                this.renderChart(this.chartData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0,
                            },
                        },
                    },
                });
            },
            watch: {
                chartData(newData) {
                    this.renderChart(newData, {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    precision: 0,
                                },
                            },
                        },
                    });
                },
            },
        },
        "horizontal-bar-chart": {
            extends: HorizontalBar,
            props: ["horizontalData"],
            mounted() {
                this.renderChart(this.horizontalBarChartData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                            }
                        }]
                    },
                    title: {
                        display: true,
                        text: this.$t('Statistics.title-links')
                    }
                });
            },
            watch: {
                horizontalData(newData) {
                    this.renderChart(newData, {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                }
                            }]
                        },
                        title: {
                            display: true,
                            text: this.$t('Statistics.title-links')
                        }
                    });
                },
            },
        },
        "doughnut-chart": {
            extends: Doughnut,
            props: ["doughnutData"],
            mounted() {
                this.renderChart(this.doughnutData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                        position: 'bottom',
                        labels: {
                            padding: 0,
                            boxWidth: 10,
                        },
                    },
                });
            },
            watch: {
                doughnutData(newData) {
                    this.renderChart(newData, {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                            position: 'bottom',
                            labels: {
                                padding: 20,
                                boxWidth: 10,
                            },
                        },
                    });
                },
            },
        },
        "bar-chart-percent": {
            extends: Bar,
            props: ["chartData"],
            mounted() {
                this.renderChart(this.chartData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                max: 100,
                                callback: function (value) {
                                    return value + "%";
                                },
                            },
                        }],
                    },
                    title: {
                        display: true,
                        text: 'Tasa de Clics entre Visitantes Únicos'
                    }
                });
            },
            watch: {
                chartData(newData) {
                    this.renderChart(newData, {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    max: 100,
                                    callback: function (value) {
                                        return value + "%";
                                    },
                                },
                            }],
                        },
                        title: {
                            display: true,
                            text: 'Tasa de Clics entre Visitantes Únicos'
                        }
                    });
                },
            },
        }
    },
    data() {
        return {
            sites: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#E57373", "#F06292", "#BA68C8", "#64B5F6", "#4FC3F7", "#81C784", "#FFD54F"],
            selectedSite: null,
            statistics:
            {
                visits: 0,
                uniques: 0,
                clicks: 0,
                unique_clicks: 0,
                total: 0,
                description: "",
                logo_path: "",
                details: [],
                visits_by_country: [],
                visits_by_region: [],
                visits_by_city: [],
                url_or_platforms: [],
                percent_unique: [],
                deviceStats: [],
            },
            chartData: null,
            chartPercent: null,
            deviceStatsData: null,
            deviceExpand: false,
            loading: false,
            siteId: this.$route.query.id || 'all',
            userId: this.$route.query.user_id || 'all',
            siteName: this.$route.query.name || 'todos',
            selectedMonth: this.formatToYearMonth(new Date()),
            selectedStartMonth: this.formatToYearMonth(new Date()),
            selectedEndMonth: this.formatToYearMonth(new Date()),
            menuStart: false,
            menuEnd: false,
            menu: false,
            lang: "es",
            horizontalBarChartData: null,
            deviceColors: null,
            countryData: [],
            regionData: [],
            cityData: [],
            tableHeaders: [
                { text: "Platforma/URL", value: "name" },
                { text: "Clicks", value: "count" },
            ],
            headersCountry: [
                { text: '', value: 'flag' },
                { text: 'Pais', value: 'name' },
                { text: 'Total', value: 'total' },
                { text: 'Porcentaje', value: 'percentage' },
            ],
            headersCity: [
                { text: 'Ciudad', value: 'name' },
                { text: 'Total', value: 'total' },
            ],
            headersRegion: [
                { text: 'Region', value: 'name' },
                { text: 'Total', value: 'total' },
            ],
            activeTab: 0,
            tabs: ['Países', 'Regiones'],
            itemsRange: [
                { text: 'Hoy', value: 0 },
                { text: 'Ayer', value: 1 },
                { text: 'Últimos 7 días', value: 2 },
                { text: 'Este mes', value: 3 },
                { text: 'Mes anterior', value: 4 },
                { text: 'Este año', value: 5 },
                { text: 'Año anterior', value: 6 },
                { text: 'Desde siempre', value: 7 },
            ],
            selectedRange: 3,
            headersConversions: [
                { text: this.$t('conversions.platform'), value: 'platform' },
                { text: this.$t('conversions.percent'), value: 'platform_percent' },
                { text: this.$t('conversions.payment'), value: 'payment_creator' },
                { text: this.$t('conversions.date'), value: 'payment_date' },
            ],
            conversions:[],
            loadingConversions:false,
            totalConversionsAmount: 0.00,
            totalSiteAmount: 0.00,
            itemsPerPage: 10,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['payment_date'],
                sortDesc: [true],
            },
            totalConversions:null,
            headersEarningsReferrals: [
                { text: 'Harmony', value: 'site_name', align: 'start' },
                { text: 'Creador', value: 'email', align: 'start' },
                { text: 'Ganacias', value: 'total_amount', align: 'start' },
                { text: 'Rango', value: 'ranges', align: 'start' },
            ],
            itemsForView: [
                {text: 'Todos', value: 0},
                {text: 'Conversiones' , value: 1},
                {text: 'Leads', value : 2},
            ],
            selectedConversions: 0,
            tot_earnings: 0,
            earnings_referrals:[],
            loadingEarningsReferrals:false,
            totalEarningsReferrals: 0.00,
        };
    },

    created() {
        this.$i18n.locale = 'es';
        this.checkLanguage();
        this.fetchSites();
    },

    computed: {
        siteNames() {
            return this.sites.map((site) => site.name);
        },
        deviceStats() {
            if (!this.statistics.details) return {};
            const deviceCounts = {};
            this.statistics.details.forEach((detail) => {
                const device = this.extractDevice(detail.user_agent);
                if (!deviceCounts[device]) {
                    deviceCounts[device] = 0;
                }
                deviceCounts[device]++;
            });
            return deviceCounts;
        },
        totalAmount() {
            const totalSiteAmount = parseFloat(this.totalSiteAmount) || 0;
            const totalConversionsAmount = parseFloat(this.totalConversionsAmount) || 0;
            const totalEarningsReferrals = parseFloat(this.totalEarningsReferrals) || 0;
            return (totalSiteAmount + totalConversionsAmount + totalEarningsReferrals).toFixed(2);
        },
    },
    methods: {

        formatDecimal(value) {
            // Verificar si el valor tiene más de dos decimales
            const parts = value.toString().split('.');

            if (parts.length === 2 && parts[1].length > 2) {
            // Si tiene más de dos decimales, cortarlo a los primeros dos sin redondear
            return parseFloat(parts[0] + '.' + parts[1].slice(0, 2));
            }

            // Si tiene dos o menos decimales, devolver el valor original
            return value;
        },

        formatCurrency(value) {
            return new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'USD',
            }).format(value);
        },

        formatDate(value) {
            return new Date(value).toLocaleDateString('es-ES');
        },

        formatPercentage(value) {
            return `${(value * 100).toFixed(0)}%`;
        },

        getAmount(value) {
            if(!value) return 0;
            let amount = value * 0.01;
            return amount.toFixed(2);
        },

        getTotEarning(value){
            return value.toFixed(2)
        },

        showSelectedRange() {
            this.fetchSites();
        },

        getAll() {
            return this.siteId == 'all' ? 'col-md-12' : 'col-md-8';
        },

        generateRandomColors(count) {
            const baseColors = [
                "#FF6384", "#36A2EB", "#FFCE56", "#E57373", "#F06292",
                "#BA68C8", "#64B5F6", "#4FC3F7", "#81C784", "#FFD54F"
            ];

            const colors = [];
            for (let i = 0; i < count; i++) {
                if (i < baseColors.length) {
                    colors.push(baseColors[i]);
                } else {
                    // Si necesitamos más colores, generamos uno aleatorio
                    const r = Math.floor(Math.random() * 256);
                    const g = Math.floor(Math.random() * 256);
                    const b = Math.floor(Math.random() * 256);
                    colors.push(`rgba(${r}, ${g}, ${b}, 0.6)`);
                }
            }
            return colors;
        },

        onStartMonthSelected(date) {
            this.selectedStartMonth = date;
            this.selectedRange = null;
            this.fetchSites();
        },

        onEndMonthSelected(date) {
            this.selectedEndMonth = date;
            this.selectedRange = null;
            this.fetchSites();
        },

        checkLanguage() {
            const storedLocale = localStorage.getItem("locale");
            this.lang = storedLocale;
        },

        async getUsersReferrals(){
            this.loadingEarningsReferrals = true;
            try {
                const response = await axios.get('/admin/get-users-earnings-statistics', {
                    params: {
                        site_id: this.siteId,
                        user_id: this.userId,
                        start_month: this.selectedStartMonth,
                        end_month: this.selectedEndMonth,
                        selecRange: this.selectedRange
                    }
                });
                this.earnings_referrals = response.data.getUsersReferralsAdmin;

                this.totalEarningsReferrals = 0;
                this.tot_earnings = response.data.total.toFixed(2)
                this.earnings_referrals.forEach(element => {
                    let amount = parseFloat(element.total_amount);
                    this.totalEarningsReferrals += amount;
                });
            } catch (error) {
                console.error(error);
            } finally {
                this.loadingEarningsReferrals = false;
            }
        },

        async getConversions(){
            this.loadingConversions = true;
            try {
                const response = await axios.get("admin/admin-conversions", {
                    params: {
                        site_id: this.siteId,
                        user_id: this.userId,
                        start_month: this.selectedStartMonth,
                        end_month: this.selectedEndMonth,
                        per_page: this.itemsPerPage,
                        selecRange: this.selectedRange,
                        type: this.selectedConversions
                    }
                });
                this.conversions = response.data.conversions;
                this.totalConversions = response.data.total;

                this.totalConversionsAmount = 0;
                this.conversions.forEach(element => {
                    let amount = parseFloat(element.payment_creator);
                    this.totalConversionsAmount += amount;
                });
            } catch (error) {
                console.error('Error fetching conversions:', error);
            } finally {
                this.loadingConversions = false;
            }
        },

        async fetchSites() {
            this.loading = true;
            this.getConversions();
            this.getUsersReferrals();
            try {
                const response = await axios.get("admin/site-statistics", {
                    params: {
                        site_id: this.siteId,
                        user_id: this.userId,
                        start_month: this.selectedStartMonth,
                        end_month: this.selectedEndMonth,
                        selectedRange: this.selectedRange === 3 || !this.itemsRange[this.selectedRange] ? '' : this.itemsRange[this.selectedRange].text
                    }
                });
                this.sites = response.data;
                if (this.sites.length > 0) {
                    if (this.selectedSite == null) {
                        this.selectedSite = this.sites[0].name;
                    }
                    await this.fetchSiteStatistics();
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.error("Error fetching sites:", error);
            }
        },

        async fetchSiteStatistics() {
            const site = this.sites.find((s) => s.name === this.selectedSite);
            if (site) {
                this.statistics = site;
                this.totalSiteAmount = this.statistics.earnings.toFixed(2);


                this.countryData = this.statistics.visits_by_country.map(({ normalized_country, total, percentage }) => ({
                    name: normalized_country,
                    total,
                    percentage,
                    flag: getCountryFlagUrl(normalized_country)
                }));

                this.regionData = this.statistics.visits_by_region.map(item => ({
                    name: item.region,
                    total: item.total
                }));

                this.cityData = this.statistics.visits_by_city.map(item => ({
                    name: item.city,
                    total: item.total
                }));

                this.prepareChartData();
                this.prepareDoughnutChartData();
                this.prepareChartHorizontal();
                this.prepareChartPercent();
            }
        },

        prepareDoughnutChartData() {
            if (!this.statistics) return {};
            const deviceCounts = {};

            this.statistics.details.forEach((detail) => {
                const device = this.extractDevice(detail.user_agent);
                if (!deviceCounts[device]) {
                    deviceCounts[device] = 0;
                }
                deviceCounts[device]++;
            });

            const labelsDevice = Object.keys(deviceCounts);
            const dataDevice = Object.values(deviceCounts);

            this.deviceColors = this.generateRandomColors(labelsDevice.length);

            this.deviceStatsData = {
                labels: labelsDevice,
                datasets: [
                    {
                        backgroundColor: this.deviceColors,
                        data: dataDevice,
                        borderWidth: 0.5,
                    },
                ],
            };
        },

        extractDevice(userAgent) {
            const deviceRegex = /\(([^)]+)\)/;
            const match = userAgent.match(deviceRegex);
            return match ? match[1].split(";")[0] : userAgent;
        },

        prepareChartData() {
            if (!this.statistics) return;
            const details = this.statistics.details;
            const groupedDetails = details.reduce((acc, detail) => {
                const date = moment(detail.time).format("YYYY-MM-DD");
                if (!acc[date]) {
                    acc[date] = {
                        views: 0,
                        uniqueViews: 0,
                        devices: new Set(),
                        countries: new Set(),
                        regions: new Set(),
                        cities: new Set(),
                    };
                }
                acc[date].views += 1;
                acc[date].uniqueViews += detail.is_unique ? 1 : 0;

                const device = this.extractDevice(detail.user_agent);
                acc[date].devices.add(device);

                const country = detail.country || "Unknown";
                acc[date].countries.add(country);

                const region = detail.region || "Unknown";
                acc[date].regions.add(region);

                const city = detail.city || "Unknown";
                acc[date].cities.add(city);

                return acc;
            }, {});

            const labels = Object.keys(groupedDetails).sort();
            const views = [];
            const uniqueViews = [];
            const deviceViews = [];
            const countryViews = [];
            const regionViews = [];
            const cityViews = [];

            labels.forEach((date) => {
                const data = groupedDetails[date];
                views.push(data.views);
                uniqueViews.push(data.uniqueViews);
                deviceViews.push(data.devices.size);
                countryViews.push(data.countries.size);
                regionViews.push(data.regions.size);
                cityViews.push(data.cities.size);
            });

            const createGradient = (ctx, top, bottom) => {
                const bgColor = [
                    "rgba(151, 138, 206, 0.6)",
                    "rgba(151, 138, 206, 0.4)",
                    "rgba(151, 138, 206, 0.2)",
                ];
                const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
                gradientBg.addColorStop(0, bgColor[0]);
                gradientBg.addColorStop(0.5, bgColor[1]);
                gradientBg.addColorStop(1, bgColor[2]);
                return gradientBg;
            };

            this.chartData = {
                labels,
                datasets: [
                    {
                        label: this.$t("Statistics.chart-views"),
                        backgroundColor: "rgba(66, 165, 245, 0.2)",
                        borderColor: "rgba(66, 165, 245, 1)",
                        borderWidth: 1,
                        data: views,
                        type: "bar",
                    },
                    {
                        label: this.$t("Statistics.chart-uniqueViews"),
                        backgroundColor: "rgba(102, 187, 106, 0.2)",
                        borderColor: "rgba(102, 187, 106, 1)",
                        borderWidth: 1,
                        data: uniqueViews,
                        type: "bar",
                    },
                    {
                        label: this.$t("Statistics.chart-Devices"),
                        borderColor: "#ff6b6d",
                        fill: false,
                        data: deviceViews,
                        type: "line",
                    },
                    {
                        label: this.$t("Statistics.chart-Countries"),
                        data: countryViews,
                        type: "line",
                        fill: false,
                    },
                    {
                        label: this.$t("Statistics.chart-Regions"),
                        data: regionViews,
                        type: "line",
                        fill: false,
                    },
                    {
                        label: this.$t("Statistics.chart-Cities"),
                        data: cityViews,
                        type: "line",
                        borderColor: "#978ace",
                        backgroundColor: (context) => {
                            if (!context.chart.chartArea) {
                                return;
                            }
                            const { ctx, chartArea: { top, bottom } } = context.chart;
                            return createGradient(ctx, top, bottom);
                        },
                        fill: true,
                    },
                ],
            };
        },

        prepareChartPercent() {
            const percent = this.statistics.percent_unique;

            const labels = percent.map(item => item.date);
            const data = percent.map(item => item.percent);

            this.chartPercent = {
                labels: labels,
                datasets: [{
                    label: '%',
                    data: data,
                    backgroundColor: 'rgba(151, 138, 206, 0.2)',
                    borderColor: 'rgba(151, 138, 206, 1)',
                    borderWidth: 1,
                    fill: false,
                }],
            };
        },

        prepareChartHorizontal() {
            if (!this.statistics) return;
            const urlOrPlatforms = this.statistics.url_or_platforms;

            const labels = urlOrPlatforms.map(item => item.name);
            const data = urlOrPlatforms.map(item => item.count);

            const predefinedColors = [
                'rgba(151, 138, 206, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 206, 86, 0.2)'
            ];

            const backgroundColors = urlOrPlatforms.map((_, index) => predefinedColors[index % predefinedColors.length]);
            const borderColors = backgroundColors.map(color => color.replace('0.2', '1'));

            this.horizontalBarChartData = {
                labels: labels,
                datasets: [{
                    label: 'Clics',
                    data: data,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,
                    borderWidth: 1,
                    fill: false,
                }],
                options: {
                    scales: {
                        xAxes: [{
                            ticks: {
                                precision: 0,
                            },
                        }],
                    },
                },
            };
        },

        getColor(index) {
            return this.backgroundColor[index % this.backgroundColor.length];
        },

        formatNumber(value) {
            if (value >= 1000000) {
                return (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000) {
                return (value / 1000).toFixed(1) + 'k';
            } else {
                return value;
            }
        },

        formatToYearMonth(date) {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        },
    },
};
</script>
<style scoped>
.v-icon.v-icon::after {
    width: 0px !important;
}

.v-container {
    max-width: 1200px;
    margin: 0 auto;
}

.scroll-data {
    height: 270px;
    overflow-y: auto;
}

.round-tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.custom-tab {
    text-transform: none;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
}

::v-deep .v-chip__content{
    font-weight: 800;
    color: #8e84c0;
    font-size: 10px;
    height: 18px !important;
}

::v-deep .v-chip {
    height: 15px !important;
}

::v-deep .v-list-item__title{
    color: #8e84c0;
    font-weight: 600;
}

.flags {
    width: 21px;
}

.m-w {
    width: 100%;
    margin: 0;
    text-align: center
}

.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p {
    font-size: 0.8rem;
    color: rgb(151, 138, 206);
    font-weight: 600;
}

.profile,
.chart {
    gap: 20px;
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    overflow-y: hidden;
}

.profile p {
    font-size: 0.7rem;
    max-width: 250px;
    margin: auto;
}

.profile img {
    font-size: 0.8rem;
    object-fit: cover;
}

.number {
    font-size: 1.8rem;
    line-height: 1.2;
}

.icon {
    color: rgb(151, 138, 206);
}

.list {
    list-style: none;
    padding: 0;
    font-size: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.chartjs-render-monitor {
    max-height: 350px !important;
}

.color-box {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 2px;
    margin-bottom: 2px;
}

.arrow {
    transform: rotate(90deg);
    padding: 0 !important;
    cursor: pointer;
    margin-bottom: 5px;
    border-radius: 50%;
    background-color: #c9b2ff;
    color: #8a72c1;
}

.actived-arrow {
    background-color: rgb(223, 223, 223);
    color: rgb(170, 170, 170);
}

.v-card {
    box-shadow: none !important;
    width: 100% !important;
    overflow: auto;
}

.show {
    margin-left: 5px;
    font-size: 0.7rem;
}

.wrap {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu {
    width: 200px !important;
}
</style>